import { render, staticRenderFns } from "./RiskInstruction.vue?vue&type=template&id=1f5e7742&scoped=true&"
import script from "./RiskInstruction.vue?vue&type=script&lang=js&"
export * from "./RiskInstruction.vue?vue&type=script&lang=js&"
import style0 from "./RiskInstruction.vue?vue&type=style&index=0&id=1f5e7742&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f5e7742",
  null
  
)

export default component.exports