<template>
<div class="outer-wrapper">
    <h1>一、隐患分类</h1>
    <p>本次评估中，隐患点的来源被分为5类，即：</p>
    <p>1、设计标准差异：由于城市轨道交通系统相关设施设备标准更新或需求提升，某些早期建成的设施设备不符合当前设计标准或新要求；</p>
    <p>2、设备老化损伤验证可靠性差：设备可靠性差或在长期使用中老化损伤，且缺少足够的维护，导致设备可靠性下降影响安全；</p>
    <p>3、管理问题：在运营组织和安全管理方面存在的疏忽和漏洞；</p>
    <p>4、专业接口：专业设备间的物理接口，或不同专业职能部门配合衔接不畅等，所产生的或无法及时处理的问题；</p>
    <p>5、其他：任何不属于前4类的隐患点来源。</p>

    <p class="table-title">表1&nbsp;运营安全评估隐患源分类表</p>
    <table>
        <tr>
            <td>隐患源分类</td>
            <td>设计标准差异</td>
            <td>设备老化伤损严重、可靠性差</td>
            <td>管理问题</td>
            <td>专业接口</td>
            <td>其它</td>
        </tr>
        <tr>
            <td>编号</td>
            <td>A</td>
            <td>B</td>
            <td>C</td>
            <td>D</td>
            <td>E</td>
        </tr>
    </table>
    <h1>二、后果严重度等级化分与分值定义</h1>
    <p>本次评价中，隐患等级由高到低分为4级：重大、较大、一般、较小。</p>
    <p>隐患等级大小（D）由隐患事件发生的可能性（L）、后果严重程度（C）两个指标决定。</p>
    <p>D=L*C。</p>
    <table>
        <tr>
            <td colspan="2">可能性级别</td>
            <td>极低</td>
            <td colspan="2">中等</td>
            <td colspan="3">高</td>
            <td colspan="4">极高</td>
        </tr>
        <tr>
            <td colspan="2">后果严重程度</td>
            <td class="bold-text">1</td>
            <td class="bold-text">2</td>
            <td class="bold-text">3</td>
            <td class="bold-text">4</td>
            <td class="bold-text">5</td>
            <td class="bold-text">6</td>
            <td class="bold-text">7</td>
            <td class="bold-text">8</td>
            <td class="bold-text">9</td>
            <td class="bold-text">10</td>
        </tr>
        <tr>
            <td>不严重</td>
            <td class="bold-text">1</td>
            <td class="bg-green">1</td>
            <td class="bg-green">2</td>
            <td class="bg-green">3</td>
            <td class="bg-green">4</td>
            <td class="bg-green">5</td>
            <td class="bg-blue">6</td>
            <td class="bg-blue">7</td>
            <td class="bg-blue">8</td>
            <td class="bg-blue">9</td>
            <td class="bg-blue">10</td>
        </tr>
        <tr>
            <td rowspan="3">较严重</td>
            <td class="bold-text">2</td>
            <td class="bg-green">2</td>
            <td class="bg-green">4</td>
            <td class="bg-blue">6</td>
            <td class="bg-blue">8</td>
            <td class="bg-blue">10</td>
            <td class="bg-blue">12</td>
            <td class="bg-blue">14</td>
            <td class="bg-blue">16</td>
            <td class="bg-blue">18</td>
            <td class="bg-blue">20</td>
        </tr>
        <tr>
            <td class="bold-text">3</td>
            <td class="bg-green">3</td>
            <td class="bg-blue">6</td>
            <td class="bg-blue">9</td>
            <td class="bg-blue">12</td>
            <td class="bg-blue">15</td>
            <td class="bg-blue">18</td>
            <td class="bg-yellow">21</td>
            <td class="bg-yellow">24</td>
            <td class="bg-yellow">27</td>
            <td class="bg-yellow">30</td>
        </tr>
        <tr>
            <td class="bold-text">4</td>
            <td class="bg-green">4</td>
            <td class="bg-blue">8</td>
            <td class="bg-blue">12</td>
            <td class="bg-blue">16</td>
            <td class="bg-blue">20</td>
            <td class="bg-yellow">24</td>
            <td class="bg-yellow">28</td>
            <td class="bg-yellow">32</td>
            <td class="bg-yellow">36</td>
            <td class="bg-yellow">40</td>
        </tr>
        <tr>
            <td rowspan="5">严重</td>
            <td class="bold-text">5</td>
            <td class="bg-green">5</td>
            <td class="bg-blue">10</td>
            <td class="bg-blue">15</td>
            <td class="bg-blue">20</td>
            <td class="bg-yellow">25</td>
            <td class="bg-yellow">30</td>
            <td class="bg-yellow">35</td>
            <td class="bg-yellow">40</td>
            <td class="bg-yellow">45</td>
            <td class="bg-yellow">50</td>
        </tr>
        <tr>
            <td class="bold-text">6</td>
            <td class="bg-blue">6</td>
            <td class="bg-blue">12</td>
            <td class="bg-blue">18</td>
            <td class="bg-yellow">24</td>
            <td class="bg-yellow">30</td>
            <td class="bg-yellow">36</td>
            <td class="bg-yellow">42</td>
            <td class="bg-yellow">48</td>
            <td class="bg-yellow">54</td>
            <td class="bg-red">60</td>
        </tr>
        <tr>
            <td class="bold-text">7</td>
            <td class="bg-blue">7</td>
            <td class="bg-blue">14</td>
            <td class="bg-yellow">21</td>
            <td class="bg-yellow">28</td>
            <td class="bg-yellow">35</td>
            <td class="bg-yellow">42</td>
            <td class="bg-yellow">49</td>
            <td class="bg-red">56</td>
            <td class="bg-red">63</td>
            <td class="bg-red">70</td>
        </tr>
        <tr>
            <td class="bold-text">8</td>
            <td class="bg-blue">8</td>
            <td class="bg-blue">16</td>
            <td class="bg-yellow">24</td>
            <td class="bg-yellow">32</td>
            <td class="bg-yellow">40</td>
            <td class="bg-yellow">48</td>
            <td class="bg-red">56</td>
            <td class="bg-red">64</td>
            <td class="bg-red">72</td>
            <td class="bg-red">80</td>
        </tr>
        <tr>
            <td class="bold-text">9</td>
            <td class="bg-blue">9</td>
            <td class="bg-blue">18</td>
            <td class="bg-yellow">27</td>
            <td class="bg-yellow">36</td>
            <td class="bg-yellow">45</td>
            <td class="bg-yellow">54</td>
            <td class="bg-red">63</td>
            <td class="bg-red">72</td>
            <td class="bg-red">81</td>
            <td class="bg-red">90</td>
        </tr>
        <tr>
            <td>特别严重</td>
            <td class="bold-text">10</td>
            <td class="bg-blue">10</td>
            <td class="bg-blue">20</td>
            <td class="bg-yellow">30</td>
            <td class="bg-yellow">40</td>
            <td class="bg-yellow">50</td>
            <td class="bg-red">60</td>
            <td class="bg-red">70</td>
            <td class="bg-red">80</td>
            <td class="bg-red">90</td>
            <td class="bg-red">100</td>
        </tr>

    </table>
    <div class="define">
        <div>
            <div class="bg-green">较小</div>
        </div>
        <div>
            <div class="bg-blue">一般</div>
        </div>
        <div>
            <div class="bg-yellow">较大</div>
        </div>
        <div>
            <div class="bg-red">重大</div>
        </div>
    </div>
</div>
    
</template>
<style scoped>
.outer-wrapper{
    width:800px;
    margin:0 auto;
    padding-bottom: 100px;
}
h1{
    text-align: center;
    line-height:100px;
}

p{
    font-size:16px;
    text-indent: 32px;
    line-height: 32px;
}

p.table-title{
    margin:20px 0;
    text-align: center;
}

table{
    width:100%;
    border-collapse: collapse;
    font-size: 16px;
}

td{
    border:1px solid #666;
    text-align: center;
    line-height: 48px;
}

.bg-red{
    background-color: red;
    color:#fff;
}

.bg-blue{
    background-color: blue;
    color:#fff;
}

.bg-green{
    background-color: green;
    color:#fff;
}

.bg-yellow{
    background-color: yellow;
    color:#000;
}

.bold-text{
    font-weight: 600;;
}

.define{
    display: flex;
    display: -webkit-flex;
    width:100%;
    margin-top:20px;
}

.define>div{
    width:25%;
}

.define>div>div{
    margin:0 10%;
    padding: 10px;
    text-align: center;
    font-size: 18px;
    font-family: '微软雅黑';
    border-radius: 5px;
    border:1px solid #999;
}


</style>
<script>
export default {
    name:'riskInstruction',
    data() {
            return {
            };
    }
}
</script>